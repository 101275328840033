import { contactMetaForContact, contactMetaKeys, contactMetas, contactMetasForContact } from './contact-meta';
import { contactSalesRep, contacts } from './contacts';
import { docShipmentLines, docs } from './docs';
import { emailtemplates, emailtemplatesRender } from './email-templates';
import { files } from './files';
import { journals } from './journals';
import { meTasksCounter } from './me';
import { media, medias } from './medias';
import { product, products } from './products';
import { requestQuotes } from './request-quotes';
import { tracking } from './shipments';
import { addTask, task, tasks } from './tasks';
import { transactionsVatReport } from './transactions';
import { vatRates } from './vat-rates';

export type { Error, Response } from './response';

export const apiPdg = {
  files,
  medias,
  media,
  journals,
  emailtemplates,
  emailtemplatesRender,
  contacts,
  contactSalesRep,
  products,
  product,
  contactMetas,
  contactMetasForContact,
  contactMetaForContact,
  contactMetaKeys,
  transactionsVatReport,
  vatRates,
  requestQuotes,
  docShipmentLines,
  tasks,
  meTasksCounter,
  task,
  addTask,
  shipmentTracking: tracking,
  docs,
}