import VueI18n from "vue-i18n";
import Vue from "vue";
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from "@/config/languages";
import fr from "../locales/fr.json";
import { castToVueI18n, createI18n } from 'vue-i18n-bridge'

Vue.use(VueI18n, { bridge: true });

export const i18n = castToVueI18n(createI18n({
  legacy: false,
  locale: DEFAULT_LANGUAGE, // set locale
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { fr }, // set locale messages
}, VueI18n));

Vue.use(i18n);

export const t = (i18n as any).global.t;