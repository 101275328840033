import { LaPosteResponseDto } from "@/types/laposte";
import { Response } from ".";
import { apiHttp } from "../api-http";

export const tracking = async (carrier: string, trackingNumber: number | string) => {
  // TODO : réponse générique
  const res = await apiHttp.get(`backend/shipments/tracking/${carrier}/${trackingNumber}`).json<Response<LaPosteResponseDto>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
};