<template>
  <div>
    <DrawerAdmin v-if="user && user.type && (user.type & 1024) > 0" v-model="drawer" />
    <Toolbar @nav-clicked="drawer = !drawer" />
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import DrawerAdmin from "./DrawerAdmin.vue";
import Toolbar from "./core/Toolbar.vue";

const drawer = ref(false);

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

</script>
