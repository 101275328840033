<template>
  <v-navigation-drawer app clipped :value="value" @input="$emit('input', $event)">
    <v-list dense flat app>

      <v-list-item :to="i18nRoute({ name: 'dashboard' })" color="primary">
        <v-list-item-icon>
          <v-icon>{{ mdiHomeAnalytics }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ t("Dashboard") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-for="(menu, k1) in items">
        <template v-if="!menu.items">
          <v-list-item v-if="!menu.hidden" :key="k1" :to="i18nRoute({ name: menu.name, params: menu.params })" :href="menu.href ? menu.href : '#'" :target="menu.target" color="primary">
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="menu.divider" :key="menu.title"></v-divider>
        </template>

        <template v-else>
          <v-list-group :key="menu.title" :prepend-icon="menu.icon" color="secondary">
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ menu.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="item in menu.items">
              <v-list-item v-if="!item.hidden && item.title" :key="item.title" :href="item.href" :to="i18nRoute({ name: item.name, params: item.params })" color="primary">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="item.divider" :key="item.title" inset></v-divider>
            </template>
          </v-list-group>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { mdiAccountMultiple, mdiBank, mdiBullhorn, mdiCart, mdiChartBar, mdiCube, mdiImage, mdiRuler, mdiSearchWeb, mdiHomeAnalytics } from "@mdi/js";
import { useI18n } from 'vue-i18n-bridge';
import { i18nRoute } from '../plugins/Translation';

defineProps({
  value: { required: true, type: Boolean },
});

defineEmits(['input']);

const { t } = useI18n();

const items: any = [
  {
    title: t("Contact", 2),
    icon: mdiAccountMultiple,
    name: "contact",
  },
  {
    title: t("Purchase", 2),
    icon: mdiCart,
    items: [
      { title: t("Order", 2), name: "doc-purchases-order" },
      { title: t("Delivery", 2), name: "doc-purchases-delivery", hidden: true },
      { title: t("Invoice", 2), name: "doc-purchases-invoice" },
      { divider: true },
      { title: t("Payment", 2), name: "payment-purchases" },
    ],
  },
  {
    title: t("Sale", 2),
    icon: mdiChartBar,
    items: [
      { title: t("Request", 2), name: "requestquote" },
      { title: t("Order", 2), name: "doc-sales-order" },
      { title: t("Delivery", 2), hidden: true },
      { title: t("Invoice", 2), name: "doc-sales-invoice" },
      { divider: true },
      { title: t("Payment", 2), name: "payment-sales" },
      { divider: true },
      { title: t("Quote", 2), hidden: true },
      { title: t("Cart", 2), name: "cart" },
    ],
  },
  {
    title: t("Item", 2),
    icon: mdiCube,
    items: [
      { title: t("Item", 2), name: "item" },
      { title: t("Brand", 2), name: "brand" },
      { title: t("Category", 2), name: "category" },
      { title: "Avis produits", name: "product-review" },
      { title: t("FAQ"), name: "faq-entry" },
      { divider: true, },
      { title: t("Matrix", 2), name: "matrix" },
    ],
  },
  {
    title: t("Media", 2),
    name: "media",
    icon: mdiImage,
  },
  {
    title: "Marketing",
    icon: mdiBullhorn,
    items: [
      { title: "Bannières", name: "banner" },
      { title: "Codes promo", name: "coupon" },
      { title: "Témoignages", name: "sitereview" },
      { title: "Abonnements newsletter", name: "newsletter-subscriptions" },
      { title: t("Email template", 2), name: "emailtemplate" },
      { title: t("Flux"), name: "marketing-flux" },
    ],
  },
  {
    title: t("Accounting"),
    icon: mdiBank,
    items: [
      { title: "Suivi CA", name: "turnover" },
      { title: "Suivi TVA", name: "vat-report" },
      { title: t("Account", 2), name: "account" },
      { title: t("Journal", 2), name: "journal" },
      { title: t("Transaction", 2), name: "transaction" },
    ],
  },
  {
    title: "Recherche",
    icon: mdiSearchWeb,
    items: [
      { title: "Curations produits", name: "search-engine-overrides", params: { collection: 'product' } },
      { title: "Curations catégories", name: "search-engine-overrides", params: { collection: 'category' } },
      { title: "Synonymes produits", name: "search-engine-synonyms", params: { collection: 'product' } },
      { title: "Synonymes catégories", name: "search-engine-synonyms", params: { collection: 'category' } },
      { title: "Ré-indexation", name: "search-engine-reindex" },
    ],
  },
  {
    title: t("Parameter", 2),
    icon: mdiRuler,
    items: [
      { title: t("Unit", 2), name: "unit" },
      { title: t("Unit of packaging", 2), name: "conditionnementunit" },
      { title: t("Packaging", 2), name: "conditionnement" },
      { title: t("Option", 2), name: "option" },
      { title: t("Job", 2), name: "job" },
    ],
  },
];
</script>
