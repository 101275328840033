<template>
  <div>
    <label class="co-white d-none" for="language">{{ $tc("Language") }}</label>
    <select id="language" name="language" class="LanguageSwitcher white--text" @change="changeLanguage">
      <option v-for="language in supportedLanguages" :key="language" :selected="isCurrentLanguage(language)" :class="{ 'is-selected': isCurrentLanguage(language) }" :value="language">
        {{ language }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { Trans } from "../plugins/Translation";
import { computed } from "vue";
import { useRouter } from "vue-router/composables";

const $router = useRouter();
const currentLanguage = computed(() => Trans.currentLanguage);
const supportedLanguages = computed(() => Trans.supportedLanguages);

function changeLanguage(e) {
  const lang = e.target.value;
  const to = $router.resolve({ params: { lang } });
  return Trans.changeLanguage(lang).then(() => {
    $router.push(to.location);
  });
}

function isCurrentLanguage(language) {
  return language === currentLanguage.value;
}
</script>
