
import { Response } from '.';
import { apiHttp } from '../api-http';
import { filteredGetBuilder } from './response';

export const EmailTemplateTypeEnum = {
  CUSTOMER: 1,
  SUPPLIER: 2,
  PAYMENT: 16,
  QUOTE: 32,
  ORDER: 64,
  TRACKING: 128,
  INVOICE: 256,
  PRODUCT_REVIEW: 512,
  REQUEST: 1024,
  ABANDONED_CART: 2048,
  SHIPPING_SLIP: 4096,
  FAQ: 8192,
};

export interface EmailTemplateDto {
  id: number;
  automatic?: boolean;
  type: number; // TODO EmailTemplateTypeEnum;
  name: string;
  subject: string;
  body: string;
  delay?: number;
  hooks?: string;
  input_action?: string;
  attachements?: string;
}

export interface EmailTemplateRenderDto {
  subject: string;
  body: string;
}

export const emailtemplatesRender = async (templateId: string | number, data: any) => {
  console.log(data);
  const res = await apiHttp.post(`backend/emailtemplates/${templateId}/render`, { json: data }).json<Response<EmailTemplateRenderDto>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
}

export const emailtemplates = filteredGetBuilder<EmailTemplateDto>(`backend/emailtemplates`);
