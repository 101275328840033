import { AppNotification } from '@/types/app-notification';
import mitt from 'mitt';

const emitter = mitt();

export default {
  on: emitter.on,
  off: emitter.off,
  emit: emitter.emit,
  error: (err: unknown) => emitter.emit('error', err),
  notification: (notif: AppNotification) => emitter.emit('notification', notif),
};