import { Response } from ".";
import { apiHttp } from "../api-http";

export const meTasksCounter = async () => {
  const res = await apiHttp.get(`backend/me/tasks-counter`).json<Response<{ count: number, count_overdue: number }>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
};
