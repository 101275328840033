import { apiHttp } from "../api-http";
import { Response } from "./response";

export interface TransactionVatReportEntryDto {
  account_id: number;
  label: string;
  amount: string;
}

export interface TransactionVatReportDto {
  year: string;
  month: string;
  entries: TransactionVatReportEntryDto[];
}

export const transactionsVatReport = async (yearMonth: string) => {
  const res = await apiHttp.get(`backend/transactions/vat-report/${yearMonth}`).json<Response<TransactionVatReportDto>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
};