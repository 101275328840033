import Vue from "vue";
import Vuetify from "vuetify/lib";
import { getCurrentInstance } from 'vue'
import fr from 'vuetify/src/locale/fr'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  lang: {
    locales: { fr },
    current: "fr",
  },
});


export function useVuetify() {
  const instance = getCurrentInstance()
  if (!instance) {
    throw new Error(`useVuetify should be called in setup().`)
  }
  return instance.proxy.$vuetify;
}

export function useDisplay() {
  return useVuetify().breakpoint;
}