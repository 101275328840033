
import { apiHttp } from '../api-http';
import { Response, filteredGetBuilder } from './response';

export interface MediaDto {
  id: number;
  alt: string;
  name: string;
  height: number;
  width: number;
  modified: string | Date;
}

export const medias = filteredGetBuilder<MediaDto>(`backend/medias`);

export const media = async (id: number | string) => {
  const res = await apiHttp.get(`backend/medias/${id}`).json<Response<MediaDto>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
};
