
import { ContactDto } from '@/services/api-pdg/contacts';
import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthStore = defineStore('auth', () => {
  const token = ref<string>();
  const refreshToken = ref<string>();
  const user = ref<ContactDto>();

  async function signIn(credentials: any) {
    try {
      const response = await axios.post("auth/signin", credentials);
      if (response.data.data.error) throw Error(response.data.data.message);
      token.value = response.data.data.token;
      refreshToken.value = response.data.data.refresh_token;

      const responseMe = await axios.get("/me/me");
      user.value = responseMe.data.data;
    } catch (e) {
      token.value = undefined;
      refreshToken.value = undefined;
      user.value = undefined;
    }
  }


  async function signOut() {
    await axios.post("auth/signout");
    token.value = undefined;
    refreshToken.value = undefined;
    user.value = undefined;
  }

  async function refreshAccessToken() {
    const response = await axios.post('auth/refresh', { refresh_token: refreshToken.value });
    token.value = response.data.data.token;
    refreshToken.value = response.data.data.refresh_token;
  }

  return {
    token,
    refreshToken,
    user,
    signIn,
    signOut,
    refreshAccessToken,
  };
}, {
  persist: {
    paths: ['token', 'refreshToken', 'user'],
  }
});