import { apiHttp } from "../api-http";
import { Response, filteredGetBuilder } from "./response";

export const ContactMetaKeyEnum = {
  PAYMENT_DELAY_ORDER: "PAYMENT_DELAY_ORDER",
  PAYMENT_DELAY_INVOICE: "PAYMENT_DELAY_INVOICE",
  PAYMENT_DELAY_MODE: "PAYMENT_DELAY_MODE",
  ESCOMPTE: "ESCOMPTE",
  DISPUTE: "DISPUTE",
  COMMENTAIRE: "Commentaire",
};

export const ContactMetaValueEnum = {
  PAYMENT_DELAY_MODE_FDM: "FDM",
  PAYMENT_DELAY_MODE_FDM15: "FDM15",
};

export interface ContactMetaDto {
  id: number;
  contact_id: number;
  mkey: string;
  mvalue: string;
}

export const contactMetas = filteredGetBuilder<ContactMetaDto>(`backend/contactmetas`);

export const contactMetasForContact = (contactId: number) => {
  return contactMetas({ contact_id: contactId }, { itemsPerPage: 10, page: 1, sortBy: [], sortDesc: [] });
}

export const contactMetaForContact = async (contactId: number, key: string) => {
  return (await contactMetasForContact(contactId)).data.find(val => val.mkey === key) ?? null;
}

export const contactMetaKeys = async () => {
  const res = await apiHttp.get(`backend/contactmetas/keys`).json<Response<string[]>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
}
