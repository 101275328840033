import { filteredGetBuilder } from "./response";

export enum ContactType {
  customer = 2,
  supplier = 4,
  bank = 8,
  competitor = 64,
  partner = 512,
  employee = 1024,
  brand = 2048,
}

export interface ContactDto {
  id: number | null;
  logo: boolean | null;
  name: string | null;
  username: string | null;
  first_name: string | null;
  last_name: string | null;
  expertise: number | null;
  person: number | null;
  created: string | null;
  modified: string | null;
  active: number | null;
  type: ContactType | number | null;
  // unq_username: string | null;
  company: string | null;

  // TODO : remove
  panel: number | null;

}

export const contacts = filteredGetBuilder<ContactDto>(`backend/contacts`);

export const contactSalesRep = () => filteredGetBuilder<ContactDto>(`backend/contacts`)({ type: 1024 /* TODO: employee */ }, { itemsPerPage: 30, page: 1, sortBy: ["u.name"], sortDesc: [false] });
