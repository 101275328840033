import { ContactDto } from "@/services/api-pdg/contacts";
import dayjs from "dayjs";
import { defineStore } from 'pinia';
import { ref } from 'vue';

export type ConnectedUser = ContactDto & { title: string, date: string, color: string, location: string, x: number, y: number, dataType: string, dataId: string, };

export const useUsersStore = defineStore('users', () => {
  const users = ref<ConnectedUser[]>();

  function update(data: ConnectedUser) {
    const currentUsers = users.value?.filter((val) => val.username !== data.username && dayjs().diff(val.date, 'minutes') < 5) ?? [];
    currentUsers.push(data);

    users.value = currentUsers;
  }

  return {
    users,
    update,
  };
});
