<template>
  <v-app>
    <Navigation />
    <v-main>
      <router-view :key="$route.path" />
    </v-main>
    <v-snackbar v-model="notification.visible" :timeout="notification.timeout" vertical :color="notification.type === 'error' ? 'red' : (notification.type === 'success' ? 'green' : undefined)">
      <div class="text-subtitle-1 pb-2">{{ notification.title }}</div>

      <p>{{ notification.description }}</p>

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="notification.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script setup lang="ts">
import axios, { AxiosError } from "axios";
import { HTTPError } from "ky";
import { ref } from "vue";
import Navigation from "./components/Navigation.vue";
import eventBus from "./services/event-bus";
import { AppNotification } from "./types/app-notification";

const notification = ref<Partial<AppNotification> & { visible: boolean }>({ visible: false });

// eslint-disable-next-line @typescript-eslint/no-misused-promises
eventBus.on('error', async (err) => {
  console.error(err);

  const val: AppNotification = {
    title: 'Une erreur est survenue',
    description: '',
    timeout: 10000,
  }

  if (err instanceof AxiosError && axios.isAxiosError(err)) {
    if (err.response && err.response.status !== 0) {
      if (err.response.status === 404) {
        val.title = `Élément non trouvé`;
        val.description = `L'élément demandé n'existe pas`;
      } else {
        val.title = `Erreur ${err.response?.status}: ${err.response?.statusText}`;
        val.description = (err.response?.data as any)?.data?.message ?? (err.response?.data as any)?.error?.description ?? `${err.response?.data}`;
      }
    } else {
      val.title = `${err.code}`;
      val.description = `${err.message}`;
    }
  } else if (err instanceof HTTPError) {
    const errorJson = await err.response.json();
    val.title = `${errorJson.statusCode} ${errorJson.error.type}`;
    val.description = `${errorJson.error.description}`;
  } else {
    val.description = `${err}`;
  }

  notification.value = {
    ...val,
    visible: true,
  }
});

eventBus.on('notification', (val) => {
  notification.value = {
    ...(val as AppNotification),
    visible: true,
  }
});

</script>
