import { apiHttp } from "../api-http";
import { Response, filteredGetEnhancedBuilder } from "./response";

export interface TaskDto {
  id: number;
  title: string;
  content: string;
  created: Date | string;
  modified: Date | string;
  archived: Date | string | null;
  due_date: Date | string | null;
  product_id?: number;
  doc_id?: number;
  request_id?: number;
  owner_user_id: number;
  owner_first_name?: string;
  owner_last_name?: string;
  owner_logo?: boolean;
  doc_type?: number;
  doc_doc_id?: number;
}

export const tasks = filteredGetEnhancedBuilder<TaskDto, { count_open: number }>(`backend/tasks`);

export const task = async (id: number | string) => {
  const res = await apiHttp.get(`backend/tasks/${id}`).json<Response<TaskDto>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
};

export const addTask = async (task: Partial<TaskDto>) => {
  return await apiHttp.post(`backend/tasks`, { json: task });
}