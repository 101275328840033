import { Trans } from "@/plugins/Translation";
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/:lang",
      component: {
        template: "<router-view></router-view>",
      },
      beforeEnter: Trans.routeMiddleware,
      children: [
        {
          path: "/",
          name: "home",
          component: () => import(/*webpackChunkName: "Home" */ "@/views/Home.vue"),
        },
        {
          name: "signin",
          path: "signin",
          component: () => import(/*webpackChunkName: "Home" */ "@/views/Signin.vue"),
        },
        {
          name: "dashboard",
          path: "dashboard",
          component: () => import(/*webpackChunkName: "Home" */ "@/views/dashboard/Dashboard.vue"),
        },
        {
          name: "account",
          path: "accounts",
          component: () => import(/*webpackChunkName: "Account" */ "@/views/account/AccountIndex.vue"),
        },
        {
          name: "account-create",
          path: "accounts/create",
          props: true,
          component: () => import(/*webpackChunkName: "Account" */ "@/views/account/AccountCreate.vue"),
        },
        {
          name: "account-view",
          path: "accounts/:id",
          component: () => import(/*webpackChunkName: "Account" */ "@/views/account/AccountView.vue"),
          props: true,
        },
        {
          name: "account-update",
          path: "accounts/update/:id",
          component: () => import(/*webpackChunkName: "Account" */ "@/views/account/AccountUpdate.vue"),
          props: true,
        },
        {
          name: "banner",
          path: "banners",
          component: () => import("@/views/banner/BannerList.vue"),
        },
        {
          name: "brand",
          path: "brands",
          component: () => import("@/views/brand/BrandIndex.vue"),
        },
        {
          name: "brand-view",
          path: "brands/:id",
          props: true,
          component: () => import("@/views/brand/BrandView.vue"),
        },
        {
          name: "brand-update",
          path: "brands/update/:id",
          props: true,
          component: () => import("@/views/brand/BrandUpdate.vue"),
        },
        {
          name: "cart",
          path: "carts",
          props: true,
          component: () => import("@/views/cart/CartIndex.vue"),
        },
        {
          name: "cart-view",
          path: "carts/:id",
          props: true,
          component: () => import("@/views/cart/CartView.vue"),
        },
        {
          name: "category",
          path: "categories",
          component: () => import("@/views/category/CategoryIndex.vue"),
        },
        {
          name: "category-view",
          path: "categories/:id",
          props: true,
          component: () => import("@/views/category/CategoryView.vue"),
        },
        {
          name: "category-update",
          path: "categories/update/:id",
          props: true,
          component: () => import("@/views/category/CategoryUpdate.vue"),
        },
        {
          name: "conditionnement",
          path: "conditionnements",
          component: () => import("@/views/conditionnement/ConditionnementIndex.vue"),
        },
        {
          name: "conditionnement-view",
          path: "conditionnements/:id",
          props: true,
          component: () => import("@/views/conditionnement/ConditionnementView.vue"),
        },
        {
          name: "conditionnement-update",
          path: "conditionnements/update/:id",
          props: true,
          component: () => import("@/views/conditionnement/ConditionnementUpdate.vue"),
        },
        {
          name: "conditionnementunit",
          path: "conditionnementunits",
          component: () => import("@/views/conditionnementunit/Index.vue"),
        },
        {
          name: "conditionnementunit-view",
          path: "conditionnementunits/:id",
          props: true,
          component: () => import("@/views/conditionnementunit/View.vue"),
        },
        {
          name: "conditionnementunit-update",
          path: "conditionnementunits/update/:id",
          props: true,
          component: () => import("@/views/conditionnementunit/Update.vue"),
        },
        {
          name: "contact",
          path: "contacts",
          component: () => import("@/views/contact/ContactIndex.vue"),
        },
        {
          name: "contact-view",
          path: "contacts/:id",
          props: route => ({ id: parseInt(route.params.id) }),
          component: () => import("@/views/contact/ContactView.vue"),
        },
        {
          name: "contact-update",
          path: "contacts/update/:id",
          props: true,
          component: () => import("@/views/contact/Update.vue"),
        },
        {
          name: "doc-create",
          path: "docs/create",
          props: true,
          component: () => import("@/views/doc/DocCreate.vue"),
        },
        {
          name: "doc-purchases-invoice",
          path: "purchases/invoices",
          component: () => import("@/views/doc/purchases/Invoice.vue"),
        },
        {
          name: "doc-purchases-order",
          path: "purchases/orders",
          component: () => import("@/views/doc/purchases/Order.vue"),
        },
        {
          name: "doc-sales-order",
          path: "sales/orders",
          component: () => import("@/views/doc/sales/Order.vue"),
        },
        {
          name: "doc-sales-invoice",
          path: "sales/invoices",
          component: () => import("@/views/doc/sales/Invoice.vue"),
        },
        {
          name: "doc-payment-create",
          path: "payment/create",
          component: () => import("@/views/doc/PaymentCreate.vue"),
          props: true,
        },
        {
          name: "doc-update",
          path: "docs/update/:id",
          props: true,
          component: () => import("@/views/doc/DocUpdate.vue"),
        },
        {
          name: "doc-view",
          path: "docs/:id",
          props: true,
          component: () => import("@/views/doc/DocView.vue"),
        },
        {
          name: "emailtemplate",
          path: "emailtemplates",
          component: () => import("@/views/email-template/EmailTemplateIndex.vue"),
        },
        {
          name: "emailtemplate-update",
          path: "emailtemplates/update/:id",
          props: true,
          component: () => import("@/views/email-template/EmailTemplateUpdate.vue"),
        },
        {
          name: "emailtemplate-view",
          path: "emailtemplates/:id",
          props: true,
          component: () => import("@/views/email-template/EmailTemplateView.vue"),
        },
        {
          name: "faq-entry",
          path: "faqentry",
          props: true,
          component: () => import("@/views/faq-entry/FaqEntryList.vue"),
        },
        {
          name: "faq-entry-view",
          path: "faqentry/:faqEntryId",
          props: true,
          component: () => import("@/views/faq-entry/FaqEntryView.vue"),
        },
        {
          name: "item",
          path: "items",
          component: () => import("@/views/item/ItemIndex.vue"),
        },
        {
          name: "item-import",
          path: "items/imports",
          component: () => import("@/views/item/Import.vue"),
        },
        {
          name: "item-view",
          path: "items/:id",
          props: true,
          component: () => import("@/views/item/ItemView.vue"),
        },
        {
          name: "item-update",
          path: "items/update/:id",
          props: true,
          component: () => import("@/views/item/ItemUpdate.vue"),
        },
        {
          name: "job",
          path: "jobs",
          component: () => import("@/views/job/JobIndex.vue"),
        },
        {
          name: "job-view",
          path: "jobs/:id",
          props: true,
          component: () => import("@/views/job/JobView.vue"),
        },
        {
          name: "job-update",
          path: "jobs/update/:id",
          props: true,
          component: () => import("@/views/job/JobUpdate.vue"),
        },
        {
          name: "journal",
          path: "journals",
          component: () => import("@/views/journal/JournalIndex.vue"),
        },
        {
          name: "journal-view",
          path: "journals/:id",
          props: true,
          component: () => import("@/views/journal/JournalView.vue"),
        },
        {
          name: "journal-update",
          path: "journals/update/:id",
          props: true,
          component: () => import("@/views/journal/JournalUpdate.vue"),
        },
        {
          name: "marketing-flux",
          path: "marketing/flux",
          component: () => import("@/views/marketing/Flux.vue"),
        },
        {
          name: "search-engine-reindex",
          path: "search-engine/reindex",
          component: () => import("@/views/search-engine/ReIndex.vue"),
        },
        {
          name: "search-engine-overrides",
          path: "search-engine/overrides/:collection",
          props: route => ({ collection: route.params.collection, filterByCategory: route.params.collection === 'product' }),
          component: () => import("@/views/search-engine/Overrides.vue"),
        },
        {
          name: "search-engine-synonyms",
          path: "search-engine/synonyms/:collection",
          props: true,
          component: () => import("@/views/search-engine/Synonyms.vue"),
        },
        {
          name: "matrix",
          path: "matrices",
          component: () => import("@/views/matrix/MatrixIndex.vue"),
        },
        {
          name: "matrix-view",
          path: "matrices/:id",
          props: true,
          component: () => import("@/views/matrix/MatrixView.vue"),
        },
        {
          name: "matrix-update",
          path: "matrices/update/:id",
          props: true,
          component: () => import("@/views/matrix/MatrixUpdate.vue"),
        },
        {
          name: "media",
          path: "medias",
          component: () => import("@/views/media/MediaIndex.vue"),
        },
        {
          name: "media-update",
          path: "medias/update/:id",
          props: true,
          component: () => import("@/views/media/MediaUpdate.vue"),
        },
        {
          name: "media-view",
          path: "medias/:id",
          props: true,
          component: () => import("@/views/media/MediaView.vue"),
        },
        {
          name: "newsletter-subscriptions",
          path: "newsletter-subscriptions",
          component: () => import("@/views/newsletter-subscriptions/Index.vue"),
        },
        {
          name: "option",
          path: "options",
          component: () => import("@/views/option/OptionIndex.vue"),
        },
        {
          name: "option-update",
          path: "options/update/:id",
          props: true,
          component: () => import("@/views/option/OptionUpdate.vue"),
        },
        {
          name: "option-view",
          path: "options/:id",
          props: true,
          component: () => import("@/views/option/OptionView.vue"),
        },
        {
          name: "payment-purchases",
          path: "purchases/payments",
          component: () => import("@/views/payment/PaymentIndex.vue"),
        },
        {
          name: "payment-sales",
          path: "sales/payments",
          component: () => import("@/views/payment/PaymentIndex.vue"),
        },
        {
          name: "payment-view",
          path: "payments/:id",
          props: true,
          component: () => import("@/views/payment/PaymentView.vue"),
        },
        {
          name: "requestquote",
          path: "requestquotes",
          component: () => import("@/views/request-quote/RequestQuoteIndex.vue"),
        },
        {
          name: "requestquote-view",
          path: "requestquotes/:id",
          props: true,
          component: () => import("@/views/request-quote/RequestQuoteView.vue"),
        },
        {
          name: "requestquote-update",
          path: "requestquotes/update/:id",
          props: true,
          component: () => import("@/views/request-quote/RequestQuoteUpdate.vue"),
        },
        {
          name: "product-review",
          path: "reviews",
          props: true,
          component: () => import("@/views/product-review/ProductReviewIndex.vue"),
        },
        {
          name: "product-review-view",
          path: "reviews/:id",
          props: true,
          component: () => import("@/views/product-review/ProductReviewView.vue"),
        },
        {
          name: "product-review-update",
          path: "reviews/update/:id",
          props: true,
          component: () => import("@/views/product-review/ProductReviewUpdate.vue"),
        },
        {
          name: "sitereview",
          path: "sitereviews",
          props: true,
          component: () => import("@/views/site-review/SiteReviewIndex.vue"),
        },
        {
          name: "sitereview-view",
          path: "sitereviews/:id",
          props: true,
          component: () => import("@/views/site-review/SiteReviewView.vue"),
        },
        {
          name: "sitereview-update",
          path: "sitereviews/update/:id",
          props: true,
          component: () => import("@/views/site-review/SiteReviewUpdate.vue"),
        },
        {
          name: "transaction",
          path: "transactions",
          component: () => import("@/views/transaction/TransactionIndex.vue"),
        },
        {
          name: "transaction-view",
          path: "transactions/:mvt",
          component: () => import("@/views/transaction/TransactionView.vue"),
        },
        {
          name: "turnover",
          path: "turnover",
          component: () => import("@/views/turnover/Turnover.vue"),
        },
        {
          name: "todo",
          path: "me/todo",
          component: () => import("@/components/Todo.vue"),
        },
        {
          name: "unit",
          path: "units",
          component: () => import("@/views/unit/UnitIndex.vue"),
        },
        {
          name: "unit-update",
          path: "units/update/:id",
          props: true,
          component: () => import("@/views/unit/UnitUpdate.vue"),
        },
        {
          name: "unit-view",
          path: "units/:id",
          props: true,
          component: () => import("@/views/unit/UnitView.vue"),
        },
        {
          name: "vat-report",
          path: "vat-report",
          props: true,
          component: () => import("@/views/vat-report/VatReport.vue"),
        },
        {
          name: "coupon",
          path: "coupons",
          component: () => import("@/views/coupon/CouponList.vue"),
        },
        {
          name: "task",
          path: "tasks",
          component: () => import("@/views/task/TaskIndex.vue"),
        },
        {
          path: "/(.*)*",
          component: () => import(`@/views/404.vue`),
        },
      ],
    },
    {
      // Redirect user to supported lang version.
      path: "/(.*)*",
      redirect() {
        return Trans.getUserSupportedLang();
      },
    },
  ],
});
