import { nextTick } from "vue";

function updateDataId(el, binding, vnode) {
  nextTick(() => {
    for (const trEl of el.querySelectorAll('tr')) {
      const dataIdEl = trEl.querySelector('[data-id]');
      if (dataIdEl) {
        trEl.setAttribute('data-id', dataIdEl.getAttribute('data-id') ?? '');
      }
    }
  });
}

export default {
  update: updateDataId,
  bind: updateDataId,
};