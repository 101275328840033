
import { DocDto, DocTypeEnum } from '@/types/doc';
import { ShipmentLineDto } from '@/types/shipment';
import { Response } from '.';
import { apiHttp } from '../api-http';
import { filteredGetEnhancedBuilder } from './response';

export const docShipmentLines = async (docId: number | string, shipmentId: number | string) => {
  const res = await apiHttp.get(`backend/docs/${docId}/shipment-lines`, {
    searchParams: {
      shipment_id: shipmentId,
    },
  }).json<Response<ShipmentLineDto[]>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
};

export const docs = filteredGetEnhancedBuilder<DocDto, { type: DocTypeEnum }>(`backend/docs`);
