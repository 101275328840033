import { PageState } from '@/store/page-state';
import useSWRV from 'swrv';
import { Ref } from 'vue';
import { apiPdg } from './api-pdg';
import { ContactMetaKeyEnum } from './api-pdg/contact-meta';
import { ContactDto } from './api-pdg/contacts';
import { DocTypeEnum } from '@/types/doc';

export function useFiles(folder: string | number, key: string | number) {
  const { data, error, isValidating, mutate } = useSWRV(`files/${folder}/${key}`, () => apiPdg.files(folder, key));

  return {
    files: data,
    isValidating,
    error,
    mutate,
  }
}

export function useMedias(pageState: Ref<PageState>) {
  const { data, error, isValidating, mutate } = useSWRV(() => !pageState.value ? undefined : `medias/${JSON.stringify(pageState.value)}`, () => apiPdg.medias(pageState.value.filters, pageState.value.options));

  return {
    medias: data,
    isValidating,
    error,
    mutate,
  }
}

export function useMedia(id: number | string) {
  const { data, error, isValidating, mutate } = useSWRV(() => `medias/${id}`, () => apiPdg.media(id));

  return {
    media: data,
    isValidating,
    error,
    mutate,
  }
}

export function useJournals(pageState: Ref<PageState>) {
  const { data, error, isValidating, mutate } = useSWRV(() => !pageState.value ? undefined : `journals/${JSON.stringify(pageState.value)}`, () => apiPdg.journals(pageState.value.filters, pageState.value.options));

  return {
    journals: data,
    isValidating,
    error,
    mutate,
  }
}

export function useRequestQuotes(pageState: Ref<PageState>) {
  const { data, error, isValidating, mutate } = useSWRV(() => !pageState.value ? undefined : `requestquotes/${JSON.stringify(pageState.value)}`, () => apiPdg.requestQuotes(pageState.value.filters, pageState.value.options));

  return {
    requestQuotes: data,
    isValidating,
    error,
    mutate,
  }
}

export function useContacts(pageState: Ref<PageState>) {
  // const fetcher = debounce((filters, options) => apiPdg.contacts(filters, options), 500);
  const { data, error, isValidating, mutate } = useSWRV(() => !pageState.value ? undefined : `contacts/${JSON.stringify(pageState.value)}`, () => apiPdg.contacts(pageState.value.filters, pageState.value.options));

  return {
    contacts: data,
    isValidating,
    error,
    mutate,
  }
}

export function useContactSalesRep() {
  const { data, error, isValidating, mutate } = useSWRV(`contacts/salesRep`, () => apiPdg.contactSalesRep(), { dedupingInterval: 60000 });

  return {
    salesRep: data,
    isValidating,
    error,
    mutate,
  }
}

export function useContactDispute(contactId: number) {
  const { data, error, isValidating, mutate } = useSWRV(() => `contactmetas/${contactId}/dispute`, () => apiPdg.contactMetaForContact(contactId, ContactMetaKeyEnum.DISPUTE));

  return {
    contactDispute: data,
    isValidating,
    error,
    mutate,
  }
}

export function useContactMetaKeys() {
  const { data, error, isValidating, mutate } = useSWRV(() => `contactmetas/keys`, () => apiPdg.contactMetaKeys());

  return {
    contactMetaKeys: data,
    isValidating,
    error,
    mutate,
  }
}

export function useContactMetaForKey(contactId: Ref<number | null | undefined>, key: string) {
  const { data, error, isValidating, mutate } = useSWRV(() => !contactId.value || !key ? undefined : `contact/${contactId.value}/metas/${key}`, () => apiPdg.contactMetaForContact(contactId.value!, key));

  return {
    contactMeta: data,
    isValidating,
    error,
    mutate,
  }
}

export function useProduct(productId: number | string) {
  const { data, error, isValidating, mutate } = useSWRV(() => `product/${productId}`, () => apiPdg.product(productId));

  return {
    product: data,
    isValidating,
    error,
    mutate,
  }
}

export function useTransactionsVatReport(yearMonth: Ref<string>) {
  const { data, error, isValidating, mutate } = useSWRV(() => yearMonth.value && `transaction/vat-report/${yearMonth.value}`, () => apiPdg.transactionsVatReport(yearMonth.value));

  return {
    vatReport: data,
    isValidating,
    error,
    mutate,
  }
}

export function useTasks(pageState: Ref<PageState | undefined>) {
  const { data, error, isValidating, mutate } = useSWRV(() => !pageState.value ? undefined : `tasks/${JSON.stringify(pageState.value)}`, () => apiPdg.tasks(pageState.value!.filters, pageState.value!.options));

  return {
    tasks: data,
    isValidating,
    error,
    mutate,
  }
}

export function useMeTasksCounter(user: Ref<ContactDto | undefined>) {
  const { data, error, isValidating, mutate } = useSWRV(() => !user.value ? undefined : `me/tasks-counter`, () => apiPdg.meTasksCounter());

  return {
    meTasksCounter: data,
    isValidating,
    error,
    mutate,
  }
}

export function useShipmentTracking(carrier: string, shipmentNumber: string) {
  const { data, error, isValidating, mutate } = useSWRV(`shipments/tracking/${carrier}/${shipmentNumber}`, () => apiPdg.shipmentTracking(carrier, shipmentNumber), { dedupingInterval: 60000 * 5 });

  return {
    tracking: data,
    isValidating,
    error,
    mutate,
  }
}

export function useDocs(pageState: Ref<PageState | undefined>, type: DocTypeEnum) {
  const { data, error, isValidating, mutate } = useSWRV(() => !pageState.value ? undefined : `docs/${type}/${JSON.stringify(pageState.value)}`, () => apiPdg.docs(pageState.value!.filters, pageState.value!.options, { type }));

  return {
    docs: data,
    isValidating,
    error,
    mutate,
  }
}